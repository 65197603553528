.Header {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: auto;
  backdrop-filter: blur(40px);
  z-index: 5;
  border-bottom: 1px solid #e9e9e99c;
  // HeaderTopComputer
  .HeaderTopComputer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a1768;
    width: 100%;
    height: 80px;
    padding: 30px 5%;
    box-shadow: 0px 10px 15px -3px #0000001a;
    z-index: 1;
    position: relative;
    .Header1 {
      display: flex;
      .Header1Box {
        padding: 20px 20px;
        .Header1BoxHedaer {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          h1,
          a {
            white-space: nowrap;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            margin-right: 10px;
            padding-bottom: 0px;
            border-bottom: 2px solid #ffffff;
          }
          img {
            width: 20px;
            margin-bottom: 0px;
            filter: invert(1);
          }
        }
        .Header1BoxMenu {
          display: flex;
          flex-wrap: wrap;
          // flex-direction: column;
          transition: 0.3s all;
          visibility: hidden;
          opacity: 0.1;
          position: absolute;
          top: 80px;
          left: 0%;
          background-color: white;
          clip-path: inset(0px -10px -10px -10px);
          box-shadow: 0 0 15px #f4f4fb6d;
          border: 1px solid #e9e9e988;
          padding: 10px 10px;
          width: calc(100% - 40%);
          margin-left: 20%;
          .Header1BoxMenuBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            border-bottom: 1px solid #e9e9e988;
            padding: 15px 15px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            color: #2a1768;
          }
          .Header1BoxMenuBox:hover {
            background-color: #0156ff11;
            border-radius: 8px;
          }
          .Header1BoxMenuBox:last-child {
            border-bottom: none;
          }
        }
      }
      .Header1Box:hover {
        .Header1BoxHedaer {
          h1 {
            border-bottom: 2px solid #2a1768;
          }
        }
        .Header1BoxMenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .Header2Logo {
        display: flex;
        img {
          width: 220px;
          height: 70px;
          border-radius: 3px;
          margin-right: 10px;
          filter: invert(0);
        }
        p {
          color: white;
          font-weight: 300;
          font-size: 14px;
        }
      }
      .Header2LogoName {
        display: none;
        flex-direction: column;
        height: 50px;

        a {
          font-size: 28px;
          white-space: nowrap;
          font-weight: 700;
          transform: scale(1, 1.2);
          color: #ffffff;
        }
        p {
          color: white;
          font-weight: 300;
          font-size: 14px;
          margin-top: -3px;
        }
      }
    }
    .Header3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      img {
        max-width: 25px;
        max-height: 25px;
        min-width: 25px;
        min-height: 25px;
        filter: invert(1);
        cursor: pointer;
      }
      .menuCloseBtn {
        max-width: 20px;
        max-height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
      img:first-child {
        width: auto;
        filter: invert(1);
      }
    }
  }
  // HeaderTopPhone
  .HeaderTopPhone {
    display: flex;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 30px 5%;
    box-shadow: 0px 10px 15px -3px #0000001a;
    z-index: 1;
    background-color: #2a1768;
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .Header2Logo {
        // background-color: red;
        img {
          margin-top: 6px;
          width: 130px;
          padding-bottom: 3px;
          height: 45px;
          margin-right: 8px;
        }
      }
      .Header2LogoName {
        display: none;
        flex-direction: column;
        justify-content: center;
        height: 45px;

        a {
          font-size: 18px;
          white-space: nowrap;
          font-weight: 600;
          transform: scale(1, 1.2);
          color: #ffffff;
        }
        p {
          margin-top: -2px;
          color: white;
          font-weight: 300;
          font-size: 11px;
        }
      }
    }
    .Header3 {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 30px;
      img {
        max-width: 35px;
        max-height: 35px;
        min-width: 35px;
        min-height: 35px;
        filter: invert(0);
        cursor: pointer;
      }
      .menuCloseBtn {
        max-width: 35px;
        max-height: 27px;
        min-width: 35px;
        min-height: 27px;
      }
      img:first-child {
        width: auto;
        filter: invert(0);
      }
      .Header2Circle {
        display: flex;
        background-color: #381d90;
        padding: 8px 10px;
        border-radius: 3px;
        margin-right: 15px;
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .hdhs1 {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #5be420bd;
            z-index: 3;
            z-index: 2;
            animation: glow 0.8s linear infinite;
          }
          .hdhs2 {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #5be420;
            z-index: 3;
          }
        }
        h3 {
          font-size: 15px;
          font-weight: 500;
          margin-left: 10px;
          color: white;
        }
      }
    }
  }
  // HeaderBottom
  .HeaderBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eceef6;
    box-shadow: 0px 10px 15px -3px #0000001a;
    width: 100%;
    height: 70px;
    padding: 20px 5%;
    .Header1Main {
      display: flex;
      .Header1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 17px;
          font-weight: 500;
          color: WHITE;
          cursor: pointer;
          background-color: #2a1768;
          border-radius: 3px;
          cursor: pointer;
          padding: 10px;
          padding-left: 20px;
          margin-right: 0px;
          img {
            // background-color: white;
            height: 20px;
            width: 20px;
            margin-left: 10px;
            filter: invert(1);
          }
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .Header1:last-child {
        a {
          background-color: #da291c;
          color: white;
          img {
            filter: invert(1);
          }
        }
      }
    }
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      .Header2Location {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        img {
          width: 25px;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          margin-left: 0px;
          color: #2a1768;
          letter-spacing: -1px;
        }
      }
      .Header2Circle {
        display: flex;
        background-color: #2a17681f;
        padding: 6px 10px;
        border-radius: 5px;
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          // width: 50px;
          // height: 50px;
          .hdhs1 {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #5be420bd;
            z-index: 3;
            z-index: 2;
            animation: glow 0.8s linear infinite;
          }
          .hdhs2 {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #5be420;
            z-index: 3;
          }
        }
        h3 {
          font-size: 17px;
          font-weight: 500;
          margin-left: 10px;
        }
      }

      .Header2Box {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        padding: 15px 0px;
        h2 {
          font-size: 20px;
          font-weight: 500;
        }
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
      .Header2Box:hover {
        border-bottom: 3px solid #0000001a;
      }
    }
  }
}

@keyframes warningApply {
  0% {
    bottom: (-100%);
    opacity: 50%;
  }
  100% {
    bottom: (0);
    opacity: (100%);
  }
}
@media (max-width: 900px) {
  .Header {
    // padding: 8px 0%;
    // max-height: 55px;
    .HeaderTopComputer {
      display: none;
    }
    .HeaderTopPhone {
      display: flex;
      height: 40px;
      padding: 25px 3%;
      .Header2 {
        h1 {
          font-size: 17px;
        }
      }
    }
    .HeaderBottom {
      height: 35px;
      padding: 25px 2%;
      justify-content: space-between;
      .Header2 {
        .Header2Circle {
          display: none;
        }
        .Header2Location {
          display: flex;
          width: auto;
        margin-right: 0px;

          h1 {
            font-size : clamp(0.8rem, 3vw, 3rem);
          }
        }
        .Header2Box {
          display: none;
          h2 {
            font-size: 14px;
          }
        }
      }
      .Header1Main {
        width: auto;
        gap: 10px;
        padding: 15px 0px;
        .Header1 {
          margin-left: 0px;
          width: 100%;
          a {
            justify-content: center;
            padding: 10px 10px;
            border-radius: 3px;
            font-size: 13px;
            font-weight: 600;
            width: auto;
            img {
              // display: none;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .Header {
    // padding-right: 13%;
    // padding-left: 13%;
    .HeaderTopPhone,
    .HeaderBottom,
    .HeaderTopComputer {
      padding: 30px 13%;
    }
  }
}
@keyframes glow {
  0% {
    opacity: 30%;
    width: 10px;
    height: 10px;
  }
  100% {
    opacity: 50%;
    width: 20px;
    height: 20px;
  }
}
