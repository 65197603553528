.Footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 20px 5% 20px 5%;
  background-color: #ffffff26;
  border-top: 1px solid #4b657726;
  background-color: #eceef631;

  .Footer2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #4b657726;
    padding: 40px 0px 40px 0px;
    margin-top: 0px;
    width: 100%;
    border-bottom: 1px solid #4b657726;
    .Footer2Logo {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 28px;
        border-radius: 3px;
        margin-right: 10px;
      }
      h1 {
        font-size: 25px;
        white-space: nowrap;
        font-weight: 300;
      }
    }
    .socialmediafooter {
      img {
        width: 25px;
        margin-left: 12px;
      }
    }
  }
  .FooterTop {
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 60px 0px;
    gap: 40px;
    .SiteMapDiv {
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        font-size: 22px;
        font-weight: 500;
        color: #151515;
        border-bottom: 1px solid #4b657726;
        padding-bottom: 20px;
      }
      .SiteMapDivList {
        display: flex;
        flex-direction: column;
        a {
          padding: 0px;
          font-size: 15px;
          font-weight: 500;
          color: #151515;
          text-decoration: unset;
          margin-top: 20px;
          // white-space: nowrap;
          margin-right: 20px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .Footer3 {
    border-top: 1px solid #4b657726;
    padding: 30px 0px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    h1 {
      color: #151515;
      font-size: 13px;
      font-weight: 400;
    }
    p {
      color: #151515;
      font-size: 12px;
      font-weight: 300;
      margin-top: 20px;
    }
  }
}

@media (max-width: 790px) {
  .Footer {
    padding: 20px 5%;
    .Footer2 {
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 20px 0px 20px 0px;

      .socialmediafooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        width: 100%;
        img {
          margin-left: 0px;
          // margin-right: 20px;
        }
      }
    }
    .FooterTop {
      flex-direction: column;
      width: 100%;
      padding: 40px 0px;

      .SiteMapDiv {
        width: 100%;
        padding: 0px 0px;
        .SiteMapDivList {
          flex-direction: column;
          a {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1700px) {
  .Footer {
    padding: 20px 13%;
  }
}
