.HomePageCity {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    // MainBanner
    .HomePageCityLocation {
        padding: 10px 5% 10px 5%;
        background-color: #eceef6;
        border-bottom: 2px solid #ccd6ff;
        position: sticky;
        top: 150px;
        z-index: 4;
        h1 {
            font-size: 25px;
            font-weight: 700;
            color: #2a1768;
        }
    }
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: row-reverse;
        min-height: 65vh;
        min-height: 600px;
        min-width: 100vw;
        max-height: 65vh;
        background-color: #eceef6;
        padding: 100px 5% 0px 5%;
        overflow: hidden;
        .MainBannerLeft {
            display: flex;
            ruby-align: center;
            flex-direction: column;
            width: 50%;
            padding-left: 30px;
            margin-top: -100px;
            h1 {
                font-size: 30px;
                font-weight: 700;
                color: #2a1768;
            }
            h2 {
                font-size: 25px;
                font-weight: 400;
                color: #2a1768;
                margin-top: 15px;
            }
            a {
                align-items: center;
                justify-content: space-between;
                font-size: 32px;
                font-weight: 700;
                color: #da291c;
                cursor: pointer;
                border-radius: 3px;
                cursor: pointer;
                margin-top: 20px;
            }
            a:hover {
                text-decoration: underline;
            }
            a:last-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                background-color: #2a1768;
                color: white;
                font-size: 25px;
                padding: 18px 0px;
                font-weight: 500;

                img {
                    height: 25px;
                    width: 25px;
                    margin-left: 10px;
                    filter: invert(1);
                }
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -250px;
                margin-top: 100px;
                width: 500px;
                z-index: 2;
                border-radius: 0px;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 290px;
                bottom: -200px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -150px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                opacity: 0.5;
                background-color: #ffffff;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
    .BookAnAppointmentMainComponentEmergery {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
        background-color: #2a1768;
        padding: 20px 5% 0px 5%;

        .BookAnAppointmentMainComponentEmergeryBox {
            display: flex;
            align-items: center;
            justify-content: start;
            background-color: #1382d72b;
            padding: 12px 20px;
            border-radius: 8px;
            margin-top: 20px;

            a {
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
            }
        }
        .GoogleButt {
            display: flex;
            flex-direction: column;
            img {
                width: 150px;
                height: auto;
            }
            h6 {
                font-size: 17px;
                font-weight: 200;
                color: white;
                margin-top: 15px;
            }
        }
        .BookAnAppointmentMainComponentRates {
            margin-top: 20px;
            border-radius: 10px;
            margin-top: 10px;
            padding: 20px;
            background-color: #1382d72b;
            height: auto;
            overflow: auto;
            width: 100%;
            .BookAnAppointmentMainComponentRatesImages {
                display: flex;
                img {
                    width: auto;
                    height: 80px;
                    object-fit: cover;
                    margin-right: 20px;
                    background-color: rgb(255, 255, 255);
                    padding: 7px;
                    border-radius: 5px;
                }
            }
            h1 {
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
            }
            h2 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 10px;
            }
            h3 {
                color: #ffffff;
                font-size: 17px;
                font-weight: 400;
                margin-top: 20px;
                a {
                    color: white;
                    text-decoration: underline;
                    margin-left: 5px;
                    font-size: 12px;
                    padding-top: 20px;
                }
            }
        }
    }
    .MainBanner2Top2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background-color: #2a1768;
        position: relative;
        z-index: 2;

        .MainBanner2TopBox {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 70px 5% 70px 5%;
            background-color: #2a1768;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            width: 100%;
            // margin-top: -40px;
            h2 {
                text-align: center;
                font-size: 40px;
                font-weight: 500;
                color: white;
            }
            .MainBanner2TopBoxMenu {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 50px;
                gap: 30px;
                .MainBanner2TopBoxBox {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: white;
                    border-radius: 2px;
                    padding: 15px 10px;
                    width: 25%;
                    height: 350px;
                    position: relative;
                    margin-top: 20px;
                    img {
                        position: absolute;
                        width: 90px;
                        padding: 5px;
                        border: 3px solid #2a1768;
                        border-radius: 50%;
                        top: -45px;
                        background-color: white;
                    }
                    h3 {
                        text-align: center;
                        font-size: 24px;
                        font-weight: 700;
                        color: rgb(61, 61, 61);
                        margin-top: 40px;
                    }
                    h2 {
                        text-align: center;
                        font-size: 16px;
                        font-weight: 400;
                        color: rgb(61, 61, 61);
                        margin-top: 20px;
                    }
                    h4 {
                        text-align: center;
                        font-size: 19px;
                        font-weight: 600;
                        color: #2a1768;
                        margin-top: 30px;
                        text-decoration: underline;
                    }
                }
                .MainBanner2TopBoxBox:hover {
                    background-color: rgb(242, 242, 242);
                    border-radius: 8px;
                }
                .MainBanner2TopBoxBox:last-child {
                    border-right: none;
                }
            }
        }
    }
    // MainBanner2
    .MainBanner2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 5%;
        background-color: white;
        .MainBanner2TopTop {
            display: flex;
            flex-direction: row-reverse;
            height: auto;
            width: 100%;
            // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .MainBanner2TopTopLeft {
                width: 50%;
                height: 100%;
                padding: 8px;
                margin-left: 190px;
                background-color: white;
                border-radius: 8px 0px 0px 8px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }
            .MainBanner2TopTopRight {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;
                height: 100%;
                background-color: white;
                border-radius: 0px 8px 8px 0px;
                h4 {
                    font-size: 50px;
                    font-weight: 700;
                    color: #2a1768;
                }
                h5 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #242424;
                    margin-top: 10px;
                }
                .MainBanner2TopBox {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    background-color: white;
                    margin-top: 15px;
                    margin-top: 20px;

                    h2 {
                        text-align: center;
                        font-size: 25px;
                        font-weight: 600;
                        color: #2a1768;
                        text-decoration: underline;
                    }
                    h3 {
                        text-align: start;
                        font-size: 18px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 5px;
                    }
                }
                .MainBanner2TopBox:last-child {
                    border-bottom: none;
                }
            }
        }

        .MainBanner2TopTopBtn {
            margin-top: 40px;
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 27px;
                font-weight: 500;
                color: WHITE;
                cursor: pointer;
                background-color: #2a1768;
                border-radius: 3px;
                cursor: pointer;
                padding: 20px 40px;
                padding-left: 20px;
                margin-right: 0px;
                margin-top: 20px;

                img {
                    // background-color: white;
                    height: 20px;
                    width: 20px;
                    margin-left: 10px;
                    filter: invert(1);
                }
            }

            a:hover {
                text-decoration: underline;
            }
            a:last-child {
                background-color: #da291c;
                color: white;
                img {
                    filter: invert(1);
                }
            }
        }
    }

    // MainBanner3
    .MainBanner3 {
        padding: 20px 0%;
        background-color: white;
        .MainBanner2Bottom {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: auto;

            .MainBanner2BottomBox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 40px 5%;
                border-radius: 10px;
                width: 100%;
                height: auto;
                min-height: auto;
                margin-top: 40px;
                background-color: #eceef6;
                .MainBanner2BottomBoxLeft {
                    width: 50%;
                    padding-right: 50px;
                    h2 {
                        font-size: 50px;
                        font-weight: 700;
                        color: #2a1768;
                    }
                    h3 {
                        font-size: 17px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 10px;
                    }
                    a {
                        color: #2a1768;
                        font-size: 30px;
                        font-weight: 500;
                        cursor: pointer;
                        // background-color: #2a1768;
                        border-radius: 3px;
                        cursor: pointer;
                        margin-top: 20px;
                        text-decoration: underline;
                    }
                }
                .MainBanner2BottomBoxRight {
                    width: 50%;
                    img {
                        width: 100%;
                        height: 250px;
                        object-fit: cover;
                    }
                }
            }
            .MainBanner2BottomBox:last-child {
                flex-direction: row-reverse;
                .MainBanner2BottomBoxRight {
                    padding-right: 60px;
                }
            }
        }
    }
    // MainBanner4
    .MainBanner4 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 50vh;
        min-width: 100vw;
        background-color: #2a1768;
        padding: 80px 5% 80px 5%;
        .MainBanner4Right {
            width: 100%;
            h1 {
                font-size: 45px;
                font-weight: 600;
                color: #ffffff;
            }
            h2 {
                font-size: 17px;
                font-weight: 300;
                color: #ffffff;
                margin-top: 15px;
            }
            .MainBafnner4RightBenefits {
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;
                background-color: white;
                padding: 20px 20px;
                border-radius: 5px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                gap: 20px;
                border-radius: 3px;
                a {
                    background-color: #0156ff0e;
                    padding: 5px 7px;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgb(61, 61, 61);
                }
                a:hover {
                    background-color: #0156ff15;
                }
            }
        }
    }
    // MainBanner5
    .MainBanner5 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: auto;
        min-width: 100vw;
        // background-color: #97d7f01c;
        padding: 80px 5% 120px 5%;
        .MainBanner5Left {
            display: flex;
            flex-direction: column;
            width: 70%;
            padding-right: 20px;
            h1 {
                font-size: 33px;
                font-weight: 600;
                color: #2a1768;
            }
            .MainBanner5LeftBox {
                display: flex;
                align-items: center;
                margin-top: 35px;
                h2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 33px;
                    font-weight: 800;
                    color: #2a1768;
                    background-color: #0156ff1c;
                    border-radius: 50%;
                    min-width: 50px;
                    min-height: 50px;
                    margin-right: 20px;
                }
                .MainBanner5LeftBoxRigth {
                    h3 {
                        font-size: 23px;
                        font-weight: 500;
                        color: #2a1768;
                    }
                    h4 {
                        font-size: 17px;
                        font-weight: 400;
                        color: rgb(48, 48, 48);
                        margin-top: 10px;
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
                padding: 15px 45px;
                border-radius: 3px;
                background-color: #2a1768;
                border: unset;
                outline: unset;
                font-size: 30px;
                font-weight: 700;
                color: #ffffff;
                cursor: pointer;
                margin-top: 30px;
                img {
                    width: 20px;
                }
            }
        }
        .MainBanner5Right {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            position: relative;
            img {
                max-width: 250px;
                min-height: 400px;
                object-fit: cover;
                border-radius: 0% 10% 10% 0px;
                z-index: 2;
            }
            .MainBanner5RightBubble1 {
                position: absolute;
                left: -20px;
                bottom: 100px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #2a1768a0;
                z-index: 1;
            }
            .MainBanner5RightBubble2 {
                position: absolute;
                right: 0px;
                bottom: -100px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #da291c85;
                z-index: 3;
            }
        }
    }

    // MainBanner6
    .MainBanner6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 5% 0px 5%;
        width: 100%;
        margin-top: 0px;
        height: 40vh;
        background-color: #eceef6;
        .MainBanner6Left {
            width: 50%;
            padding-right: 50px;
            h2 {
                font-size: 50px;
                font-weight: 700;
                color: #2a1768;
            }
            h3 {
                font-size: 17px;
                font-weight: 400;
                color: #242424;
                margin-top: 10px;
                margin-bottom: 50px;
            }
            a {
                color: white;
                font-size: 20px;
                font-weight: 500;
                border-radius: 3px;
                cursor: pointer;
                text-decoration: underline;
                background-color: #2a1768;
                padding: 20px;
            }
        }
        .MainBanner6Right {
            display: flex;
            align-items: end;
            justify-content: center;
            width: 50%;
            height: 100%;
            img {
                width: 70%;
            }
        }
    }
    .MainBanner7 {
        padding: 60px 5%;
        background-color: #eceef631;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1 {
            width: 100%;
            font-size: 40px;
            font-weight: 700;
            color: #2a1768;
            text-align: start;
        }
        .MainBanner7Maein {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            margin-top: 30px;
            .MainBanner7Box {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;
                background-color: white;
                padding: 40px 30px;
                border-radius: 3px;
                h3 {
                    margin-bottom: 20px;
                    font-size: 40px;
                }
                h2 {
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #232323;
                    text-align: start;
                }
                h4 {
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                    text-align: start;
                    margin-top: 20px;
                }
            }
        }
        a {
            width: 100%;
            font-size: 27px;
            font-weight: 500;
            color: #2a1768;
            text-align: start;
            margin-top: 30px;
        }
        a:hover {
            text-decoration: underline;
        }
    }

    // Blog and Faq
    .BlogsListFaq {
        display: flex;
        flex-direction: column;
        padding: 30px 5% 60px 5%;
        width: 100%;
        .BlogsListFaqHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #2a1768;
            }
            a {
                font-size: 17px;
                font-weight: 400;
                color: #2a1768;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .BlogsListFaqList {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            .FaqListBox {
                padding: 15px 20px;
                border-left: 3px solid #2a1768;
                margin-top: 25px;
                cursor: pointer;
                a {
                    text-decoration: unset;
                    width: 100%;
                    height: 100%;
                    h1 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #151515;
                    }
                    h2 {
                        font-size: 16px;
                        font-weight: 300;
                        color: #6e6f81;
                        margin-top: 5px;
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: 500;
                        color: #2a1768;
                        margin-top: 5px;
                        margin-top: 10px;
                        span {
                            padding: 3px 5px;
                            border-left: 2px solid #2a1768;
                            width: auto;
                        }
                    }
                }
            }
            .FaqListBox:hover {
                transform: scale(1.03);
                transition: 0.5s;
            }
        }
    }
    .BlogsListReview {
        display: flex;
        flex-direction: column;
        padding: 40px 5% 70px 5%;
        width: 100%;
        gap: 20px;
        background-color: #eceef6;
        .BlogsListReviewHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #2a1768;
            }
            a {
                font-size: 17px;
                font-weight: 400;
                color: #2a1768;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .BlogsListReviewList {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .BlogsListReviewBox {
                article {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                display: flex;
                flex-direction: column;
                cursor: pointer;
                text-decoration: unset;
                width: 30%;
                background-color: white;
                margin-top: 10px;
                border-radius: 3px;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;

                .BlogsListReviewBoxLeft {
                    width: 100%;
                    max-height: 180px;
                    img {
                        max-height: 180px;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.5s ease-in-out;
                        border-radius: 5px 5px 0px 0px;
                    }
                }
                .BlogsListReviewBoxRight {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    h1 {
                        font-size: 15px;
                        font-weight: 500;
                        color: #0f212e;
                        width: 100%;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
                        width: 100%;
                        h5 {
                            color: #2a1768;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        h4 {
                            color: #4f6473;
                            font-size: 13px;
                            font-weight: 300;
                        }
                    }
                }
            }
            .BlogsListReviewBox:hover {
                position: relative;
                .BlogsListReviewBoxRight {
                    h2 {
                        color: #0f212e;
                    }
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    // ----
}
@media (max-width: 790px) {
    .HomePageCity {
        .HomePageCityLocation {
            top: 100px;
        }
        .MainBanner {
            flex-direction: column;
            padding: 120px 5% 20px 5%;
            // max-height: 100vh;
            height: auto;
            min-height: 600px;

            .MainBannerLeft {
                width: 100%;
                height: 50%;
                margin-top: -180px;
                padding-right: 0px;
                padding-left: 0px;
                h1 {
                    font-size: 30px;
                }
                h2 {
                    font-size: 20px;
                }
                a {
                    // background-color: white;
                }
            }
            .MainBannerRight {
                width: 100%;
                height: 100%;
                margin-top: 100px;
                img {
                    position: absolute;
                    bottom: -250px;
                    // margin-top: 140px;
                    width: 320px;
                    z-index: 2;
                }
                .MainBannerRightBubble1 {
                    right: 190px;
                    bottom: -220px;
                    width: 120px;
                    height: 120px;
                }
                .MainBannerRightBubble2 {
                    right: 50px;
                    bottom: -130px;
                    width: 90px;
                    height: 90px;
                }
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            width: 100%;
            .BookAnAppointmentMainComponentEmergeryBox {
                display: flex;
                align-items: center;
                justify-content: start;
                background-color: #1382d72b;
                padding: 12px 20px;
                border-radius: 8px;
                margin-top: 20px;

                a {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
            .BookAnAppointmentMainComponentRates {
                margin-top: 20px;
                border-radius: 10px;
                margin-top: 10px;
                padding: 20px;
                background-color: #1382d72b;
                width: 100%;
                padding-top: 0px;

                .BookAnAppointmentMainComponentRatesImages {
                    display: flex;
                    width: 100%;
                    // flex-direction: column;
                    overflow: auto;
                    padding-bottom: 10px;
                    img {
                        width: auto;
                        height: 60px;
                        object-fit: cover;
                        margin-right: 20px;
                        margin-top: 20px;
                    }
                }
                h1 {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 20px;
                }
                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                }
                h3 {
                    color: #ffffff;
                    font-size: 13px;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                padding: 30px 5% 20px 5%;
                h2 {
                    text-align: start;
                    font-size: 25px;
                }
                .MainBanner2TopBoxMenu {
                    flex-direction: column;
                    .MainBanner2TopBoxBox {
                        width: 100%;
                        height: auto;
                        justify-content: start;
                        padding: 20px 10px;
                        margin-bottom: 10px;

                        img {
                            width: 80px;
                        }
                    }
                }
            }
        }
        .MainBanner2 {
            padding: 20px 5%;
            height: auto;

            .MainBanner2TopTop {
                flex-direction: column-reverse;
                height: auto;

                .MainBanner2TopTopRight {
                    width: 100%;
                    h4 {
                        font-size: 40px;
                    }
                    .MainBanner2TopBox {
                        padding-left: 10px;
                        justify-content: start;
                        padding: 15px 0px;
                        h2 {
                            text-align: left;
                            font-size: 20px;
                        }
                        h3 {
                            text-align: left;
                            font-size: 16px;
                        }
                    }
                }
                .MainBanner2TopTopLeft {
                    width: 100%;
                    margin-left: 0px;
                    height: 200px;
                    padding: 0px;
                    margin-top: 30px;
                }
            }

            .MainBanner2TopTopBtn {
                padding: 0px;
                width: 100%;
                a {
                    font-size: 22px;
                }
            }
        }
        .MainBanner3 {
            .MainBanner2Bottom {
                .MainBanner2BottomBox {
                    flex-direction: column;
                    border-radius: 0px;
                    margin-top: 0px;
                    padding: 20px 5% 40px 5%;

                    .MainBanner2BottomBoxLeft {
                        width: 100%;
                        h2 {
                            font-size: 30px;
                        }
                    }
                    .MainBanner2BottomBoxRight {
                        width: 100%;
                        margin-top: 30px;
                        img {
                            height: 200px;
                        }
                    }
                }
                .MainBanner2BottomBox:last-child {
                    flex-direction: column;
                    width: 100%;
                    .MainBanner2BottomBoxRight {
                        width: 100%;
                        padding-right: 0px;
                    }
                }
            }
        }
        .MainBanner4 {
            flex-direction: column-reverse;
            padding: 50px 5% 50px 5%;
            .MainBanner4Left {
                width: 100%;
                margin-top: 50px;
                margin-bottom: -180px;
                img {
                    max-width: 150px;
                    min-height: 300px;
                }
                .MainBanner4LeftBubble1 {
                    bottom: 50px;
                    left: 20px;
                }
                .MainBanner4LeftBubble2 {
                    bottom: -50px;
                }
            }
            .MainBanner4Right {
                width: 100%;
                h1 {
                    font-size: 35px;
                }
                .MainBanner4RightBenefits {
                    padding: 20px 10px;
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
        .MainBanner5 {
            padding-top: 20px;
            padding-bottom: 70px;
            .MainBanner5Left {
                width: 100%;
                h1 {
                    font-size: 25px;
                }
                .MainBanner5LeftBox {
                    .MainBanner5LeftBoxRigth {
                        h4 {
                            font-size: 16px;
                        }
                    }
                }
                a {
                    font-size: 20px;
                    padding: 15px 15px;
                }
            }
            .MainBanner5Right {
                display: none;
            }
        }
        .MainBanner6 {
            position: relative;
            padding-bottom: 50px;
            .MainBanner6Left {
                width: 100%;
                padding-right: 0px;
                z-index: 1;
                h2 {
                    font-size: 30px;
                }
                h3 {
                    padding-right: 40%;
                }
            }
            .MainBanner6Right {
                position: absolute;
                bottom: 0;
                right: 0;
                img {
                    width: 100%;
                }
            }
        }
        .MainBanner7 {
            width: 100%;
            h1 {
                font-size: 25px;
            }
            .MainBanner7Maein {
                overflow: auto;
                justify-content: space-between;
                width: 100%;
                gap: 0px;
                padding-bottom: 30px;

                .MainBanner7Box {
                    margin-right: 30px;
                    padding: 20px 20px;
                    min-width: 300px;
                    h3 {
                        font-size: 30px;
                    }
                    h2 {
                        font-size: 13px;
                    }
                }
            }
            a {
                font-size: 20px;
            }
        }
        // Blog and Faq
        .BlogsListFaq {
            .BlogsListFaqHead {
                align-items: start;
                flex-direction: column;
                h1 {
                    margin-bottom: 10px;
                }
            }
        }
        .BlogsListReview {
            overflow: auto;
            gap: 0px;

            // BlogsListReviewHead
            .BlogsListReviewHead {
                padding-top: 0px;
            }
            .BlogsListReviewList {
                flex-direction: column;
                justify-content: start;
                .BlogsListReviewBox {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .HomePageCity {
        .HomePageCityLocation {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner {
            padding-right: 13%;
            padding-left: 13%;
            .MainBannerLeft {
                padding-left: 0px;
            }
            .MainBannerRight {
                // display: flex;
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner2Top2 {
            padding-right: 13%;
            padding-left: 13%;

            .MainBanner2TopBox {
                width: 100%;
                padding: 70px 0% 70px 0%;
            }
        }
        .MainBannerReviews {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner2 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner3 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner4 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner5 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner6 {
            padding-right: 13%;
            padding-left: 13%;
        }

        // Blog and Faq
        .BlogsListFaq {
            padding-right: 13%;
            padding-left: 13%;
        }
        .BlogsListReview {
            padding-right: 13%;
            padding-left: 13%;
        }
        // ----

        .MainBanner7 {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}
